<template>
  <LoadingLayout
    :overflow="overflow"
    :city-bg="'bg-citya-day'"
  ></LoadingLayout>
</template>

<script>
import LoadingLayout from '@/components/front/LoadingLayout.vue';

export default {
  props: ['overflow', 'bg'],
  components: {
    LoadingLayout,
  },
};
</script>
