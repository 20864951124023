<template>
  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="videoLoadStatus"
      :overflow="true"
    ></Loading>
  </transition>

  <!-- Main content -->
  <!-- Top Logo bar -->
  <div class="logo-sidebar-wrapper end-0 nav-sidebar d-flex flex-column align-items-end">
    <div class="Logo-sidebar coming-soon">
      <div class="bg-primary text-white">
        <h1 class="text-uppercase text-center text-xl-start ps-3 mb-0">CITYA</h1>
      </div>
      <!-- Exhibition date -->
      <div class="bg-primary text-center text-xl-start text-white p-3">
        <div class="h2 text-uppercase mb-0">coming soon</div>
        <div class="h4 mt-2 text-uppercase">
          {{ days }} <span class="text-secondary">DAYS </span>
          {{ hours }}
          <span class="text-secondary"> hr </span>
          {{ minutes }}
          <span class="text-secondary"> min </span>
          {{ seconds }}
          <span class="text-secondary"> sec</span>
        </div>
        <div class="h3 text-uppercase
        mb-0 mt-5 mt-xl-4">sep 17 - nov 16 2021</div>
        <div
        class="h4 text-uppercase
        mb-0 mt-2">
          opening sep 17 gmt 00:00am
        </div>
      </div>
    </div>
  </div>
  <!-- Top Logo bar -->

  <!-- Background Video -->
  <div
  v-for="(video, index) in videos"
  :key="video.src"
  class="page-center-wrapper vimeo-wrapper"
  :class="{'mb-7 mb-md-5 mb-lg-0' : videos.length - 1 === index && index !== 0}"
  >
    <iframe
      :src="'https://player.vimeo.com/video/' + video.src + '?background=1&autoplay=1&loop=1&byline=0&title=0'"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      @load="onVideoLoad(index)"
    ></iframe>
  </div>
  <!-- Background Video -->

  <!-- Bottom bar -->
  <div
    class="position-fixed row g-0 p-0 bottom-0 text-white
  w-100"
  >
    <div class="nav-bottom-bar col-12 col-lg-8 col-xl-6 bg-primary p-3
    d-flex align-items-start align-items-xl-center">
      <h2 class="text-uppercase mb-0" :class="displayForward ? 'typing-text' : ''">
        {{ displayString }}
      </h2>
    </div>
  </div>
  <!-- Bottom bar -->
</template>

<script>
import gsap from 'gsap';
import Loading from '@/components/front/LoadingGeneral.vue';

export default {
  props: ['timezone'],
  data() {
    return {
      strings: ['city as a medium coming soon'],
      counter: 0,
      iterationCounter: 0,
      displayCounter: 0,
      displayString: '',
      displayForward: true,
      timeout: 100,
      // target date
      launchingDate: 'Sep 17, 2021 00:00:00',
      // countdown timer
      days: 15,
      hours: 0,
      minutes: 0,
      seconds: 0,
      dayFormat: 0,
      hourFormat: 0,
      minuteFormat: 0,
      secondFormat: 1000,
      timeDistance: null,
      videos: [
        {
          city: 'hong-kong',
          src: '604503674',
        },
        // {
        //   city: 'macao',
        //   src: '592860998',
        // },
        // {
        //   city: 'beijing',
        //   src: '592865361',
        // },
        // {
        //   city: 'sf',
        //   src: '592860998',
        // },
        // {
        //   city: 'tallinn',
        //   src: '592865361',
        // },
        // {
        //   city: 'rome',
        //   src: '592860998',
        // },
      ],
      videoLoadLog: null,
      videoLoadStatus: true,
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Loading,
  },
  methods: {
    /**
     * Page Load effects
     */
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 1000);
    },
    onVideoLoad(index) {
      const len = this.videos.length;
      this.videoLoadLog.add(index);
      if (this.videoLoadLog.size === len) {
        // console.log('video load completed');
        this.videoLoadStatus = false;
      }
    },
    /**
     * Main methods
     */
    displayText() {
      const textLength = this.strings[this.counter].length;
      setTimeout(() => {
        this.displayString = this.strings[this.counter].substring(0, this.displayCounter);
        if (this.displayForward) {
          this.displayCounter += 1;
          if (this.displayCounter <= textLength) {
            this.displayText();
          } else {
            this.iterationCounter += 1;
            this.displayForward = false;
            this.displayText();
          }
        } else {
          // REVERSE TYPING
          this.displayCounter -= 1;
          if (this.displayCounter >= 0) {
            this.displayText();
          } else {
            this.iterationCounter += 1;
            this.displayForward = true;
            this.displayText();
          }
        }
      }, this.timeout);
    },
    format(n) {
      return (n < 10 ? '0' : '') + n;
    },
    getCountdown() {
      this.currentTime = new Date().getTime();
      this.timeDistance = (this.targetTime - this.currentTime);
      setTimeout(() => {
        this.days = Math.floor(this.timeDistance / (this.dayFormat));
        this.hours = this.format(parseInt((this.timeDistance % this.dayFormat) / this.hourFormat, 10));
        this.minutes = this.format(parseInt((this.timeDistance % this.hourFormat) / this.minuteFormat, 10));
        this.seconds = this.format(parseInt((this.timeDistance % this.minuteFormat) / this.secondFormat, 10));
        if (this.timeDistance > 0) {
          this.getCountdown();
        } else {
          // do something, count down is done
          // this.$router.push('/');
        }
      }, 1000);
    },
    initTimeNow() {
      // set the countdown date
      this.targetTime = new Date(process.env.VUE_APP_LAUNCH_DATE).getTime();

      this.minuteFormat = this.secondFormat * 60;
      this.hourFormat = this.minuteFormat * 60;
      this.dayFormat = this.hourFormat * 24;
      this.getCountdown();
    },
  },
  mounted() {
    this.videoLoadLog = new Set();
    this.initTimeNow();
    setTimeout(() => {
      this.displayText();
    }, 1000);
  },
};
</script>
