<template>
  <div
    ref="loadingBG"
    class="position-absolute overlay-top top-0 left-0
  page-center-wrapper w-100
  d-flex align-items-center justify-content-center"
    :class="{
      'bg-citya-day' : cityBg === 'bg-citya-day',
      'bg-hongkong-day' : cityBg === 'bg-hongkong-day',
      'bg-macau-day' : cityBg === 'bg-macau-day',
      'bg-beijing-day' : cityBg === 'bg-beijing-day',
      'bg-sf-day' : cityBg === 'bg-sf-day',
      'bg-tallinn-day' : cityBg === 'bg-tallinn-day',
      'bg-rome-day' : cityBg === 'bg-rome-day',
    }"
  >
    <h1
      class="blur-loop text-uppercase loading text-primary"
    >
      <!-- citya -->
      <span>
        C
      </span>
      <span>
        I
      </span>
      <span>
        T
      </span>
      <span
        class="tight-spacing"
      >
        Y
      </span>
      <span
        class="normal-spacing"
      >
        A
      </span>
    </h1>
  </div>
</template>

<script>

export default {
  props: ['overflow', 'city-bg'],
  mounted() {
    if (this.overflow) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('overflow-hidden');
    }
  },
};
</script>
